// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
    _18: ImageType,
    _19: ImageType,
    _20: ImageType,
    _21: ImageType,
    _22: ImageType,
    _23: ImageType,
    _24: ImageType,
  },
};

class Blog_2017_06_10_Heaps extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="4BIV" title="Heaps" />
        <p>
          Heaps, the biggest, baddest, best canyon in Zion. It was an absolute
          joy and I can’t wait to go again.
        </p>
        <Image caption="First light" image={data._1} />
        <p>
          I had been anticipating this trip for years. Every canyoneer dreams of
          the day they are finally ready and able to do this canyon. And
          eventually everything comes together, we have a good crew, and we
          finally execute. I had ready every trip report, every beta site, and
          every forum post that I could find. I was excited to finally witness
          the views down into Phantom Valley, walking along the knife ridge and
          eventually getting down into the canyon. I was eager to see the long
          corridors separating the <GlossaryLink>narrows</GlossaryLink>{' '}
          sections, the Iron Room, the Devil’s Pit, that other place where
          people get lost, the scary jump on sloped{' '}
          <GlossaryLink>slickrock</GlossaryLink>, and especially the final
          rappel sequence. I was excited to finally stand on the Bird Perch and
          look down into Upper Emerald Pool.
        </p>
        <p>
          I also had butterflies in my stomach the entire trip just thinking
          about how scary and dangerous the canyon could be. More than one
          canyoneer has died trying to descend Heaps and the canyon demands your
          attention and respect.
        </p>
        <hr />
        <p>
          Randy and I had been canyoneering for the past two days together and
          Cody, Ryan, and Matt joined us late the night before. We got up early,
          like 2:30 am early, and started from Lava Point at 4:20 am. Randy
          started us off at a good pace, averaging 3.7 mph hiking pace until the
          exit from the West Rim Trail.
        </p>
        <Image
          caption="View of Right Fork North Creek from the West Rim Trail. This is the Subway’s older sibling."
          image={data._2}
        />
        <p>
          The time flew by and we soon found ourselves at the first rappel down
          into Phantom Valley.
        </p>
        <Image caption="Ryan on first rappel" image={data._5} />
        <Image caption="Matt on first rappel" image={data._4} />
        <Image caption="Cody on first rappel" image={data._3} />
        <p>
          Walking along the Knife Ridge was scary for some. For me, this was the
          first of many spots that demanded my attention and reminded me this
          wasn’t a canyon to take lightly.
        </p>
        <Image
          caption="Looking onto the Knife Ridge. It is several hundred feet down on either side."
          image={data._6}
        />
        <Image
          caption="Me resting on the edge, hoping there would be no unexpected gusts of wind."
          image={data._7}
        />
        <p>
          Down, down, down we went. Down the 200′ rappel that puts us into
          Phantom Valley, down the dirt hill to the slickrock, down the
          slickrock to the sandy canyon floor, down the canyon and around the
          <GlossaryLink>potholes</GlossaryLink>, until the canyon finally
          narrowed up. We stopped for lunch (breakfast?) and put on our
          <GlossaryLink>wetsuits</GlossaryLink>. It took us about 5 hours to get
          to this point.
        </p>
        <p>
          Something happened at this location that I’m not proud of. If I’m ever
          in a canyon with you and you want to hear a good story, feel free to
          ask me about it.
        </p>
        <hr />
        <p>
          Into the canyon. Recent conditions were that it was full. That was
          true. On more than one rappel we lowered the first person who deemed
          the drop safe or unsafe and if safe. The rest of us would then jump in
          without rappelling. This saved some time and was an absolute blast.
          Consequently we blazed through the first narrows. There was one{' '}
          <GlossaryLink>keeper pothole</GlossaryLink> at the very end of this
          section but I was able to{' '}
          <GlossaryLink id="stemming">stem</GlossaryLink> over it without too
          much difficulty.
        </p>
        <Image
          caption="Randy getting ready to jump. It doesn’t look like much from the picture, but it was pretty high. None of the rest of us were willing to jump from there."
          image={data._8}
        />
        <Image
          caption="Ryan heading down on a rappel. We were the only ones who hadn’t done Heaps yet so we took the lead for the entire canyon."
          image={data._9}
        />
        <p>
          Within an hour or so, the first narrows was behind us and we were
          heading down to the second narrows, which I led. Because I was busy
          rigging, I didn’t take any good pictures.
        </p>
        <Image caption="Cody smiling for the camera" image={data._10} />
        <Image caption="Warming up between the narrows" image={data._11} />
        <Image
          caption="Long corridor between the first and second narrows"
          image={data._12}
        />
        <hr />
        <p>Third narrows – Cody the Savior</p>
        <p>
          The third narrows is the last and longest section. This section was
          probably twice as long as all the other narrow sections combined. This
          section is why people don’t like to bring camping gear into this
          canyon. There was so much up and down, climbing over{' '}
          <GlossaryLink>logjams</GlossaryLink> and flopping back down into the
          water, awkward rappels, dragging the rope bag and it fills with water,
          empties, and goes right back into the pools.
        </p>
        <p>
          Right near the beginning of this section, Ryan found a water snake
          curled up on a log at one of the rappels. In my selfish mind, I
          figured it was a goner and there was no way we could save it. Cody, on
          the other hand, wouldn’t allow that to happen. Randy skillfully
          grabbed the snake and put into Cody’s backpack.
        </p>
        <Image caption="Snake trapped in the third narrows" image={data._13} />
        <Image
          caption="Randy posing with the soon-to-be-rescued snake"
          image={data._14}
        />
        <Image
          caption="Cody carried this little guy through the entire third narrows"
          image={data._19}
        />
        <p>
          Cody carried it through the <em>entire</em> third narrows. He and Matt
          carefully lowered Cody’s backpack at each rappel, swam holding it
          above water, and finally deposited it on some warm sand where the
          snake could escape out of the canyon. I was amazed and humbled.
        </p>
        <Image caption="Third narrows" image={data._15} />
        <Image caption="Logs 30 feet above us" image={data._16} />
        <Image caption="Ryan in the Iron Room" image={data._17} />
        <Image caption="Ryan exiting a narrow section" image={data._20} />
        <hr />
        <p>
          The rush of warm air blowing through the canyon is the first sign that
          the final narrows is coming to a close. We removed the wetsuits, had
          some snacks, and went down the last rappel sequence.
        </p>
        <p>
          Either no one wanted to be the first or last person, or everyone was
          indifferent. I was noticeably scared but volunteered to rig the
          rappels and be the first person down. Once I made that decision, I
          felt confident that things would be fine.
        </p>
        <p>
          Once I climbed up to the start of the rappel sequence and saw the view
          below, all the fear I had felt turned to pure joy. What a view! All
          that preparation, lost sleep, exhaustion, freezing conditions I felt
          up to this point were worth this view.
        </p>
        <p>
          Unfortunately, though, I didn’t take a lot of time to enjoy it because
          I was all business. I had a job to do and needed to focus all my
          efforts on getting done safely.
        </p>
        <Image
          caption="Upclimb to the last rappel sequence."
          image={data._21}
        />
        <Image
          caption="View from the top of the last rappel sequence – 500 feet above Upper Emerald Pool."
          image={data._22}
        />
        <Image
          caption="Top of the final rappel, 300 feet above the people."
          image={data._23}
        />
        <p>
          Rigging the last three raps I was safe, but we had some problems with
          efficiency. We had radios but they didn’t work, so the second person
          didn’t know to set the rope lengths and I ended up waiting 10 minutes
          at each drop waiting for that to take place.
        </p>
        <p>
          Despite this, we made it down quickly enough. I was the first to drop.
          It was fun having so many cameras on me taking pictures of me going
          down. I wish I would have thought to have them send me their pictures.
          Oh well.
        </p>
        <p>
          Upon touchdown, I pulled off my{' '}
          <GlossaryLink>Valdotain Tresse</GlossaryLink> and{' '}
          <GlossaryLink>rappel device</GlossaryLink>, screamed "off rope!",
          slumped over and wept. I had so many emotions going through my mind at
          that time. I was down. I had finished the hardest canyon in Zion. Of
          course, we still had a 1.5 mile hike to the shuttle but that doesn’t
          count.
        </p>
        <p>
          I had to put my emotions on hold since I still had a job to do. I
          belayed Randy down, who belayed the others down. I took off my shoes
          and took pictures of everyone coming down. When Matt came down he
          bowed to the onlooking audience. Then we coiled and packed the ropes,
          helmets, and other gear. Finally, we spend 20 minutes talking to
          hikers about what we did, excited many kids and talked about what it
          takes to get into the sport, and finally headed down to the shuttle.
        </p>
        <Image image={data._24} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2017-06-10-heaps')(Blog_2017_06_10_Heaps);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-10.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-11.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-12.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-13.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-14.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-15.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-16.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-17.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _18: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-18.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _19: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-19.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _20: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-20.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _21: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-21.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _22: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-22.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _23: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-23.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _24: file(
      relativePath: {
        eq: "images/blog/2017-06-10-heaps/2017_06_10_Heaps-24.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
